<template>
	<img alt="Vue logo" src="./assets/logo.png" height="200">
	<HelloWorld msg="Welcome to Naba Medical"
		description="Naba Medical is dedicated to improving our patients' health and prolonging their lives." />
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
	name: 'App',
	components: {
		HelloWorld
	}
}
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
}
</style>
